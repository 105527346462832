/**
 * Hardcoded operator names.
 * We're not able to see the operating group attribute on these roles,
 * so we don't know if the user group is an "operator" or "user group".
 * We need this to populate operator inputs
 */
export const Operator = {
  METROPOLIS: 'Metropolis',
  TOWNE_PARK: 'Towne Park',
  METROPOLIS_PLATFORM: 'Metropolis Platform', // Only visible to system admins
};
export type OperatorValue = (typeof Operator)[keyof typeof Operator];
