export interface IamUser {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  phoneNumber?: string;
  operatorId?: string;
  attributes?: {
    attributes?: IamAttributes;
  };
}

export interface IamRole {
  id: string;
  roleId: string;
  name: string;
  roleType: RoleTypeValue;
  attributes?: {
    attributes?: IamAttributes;
  };
}

export interface IamAttributes {
  operatorName?: { value: [string] }; // operator name
  operatorUserGroupId?: { value: [string] }; // operator user group ID
  operatorId?: { value: [string] }; // operator resource ID
  operatingGroupType?: { value: [OperatingGroupTypeValue] };
}

export const RoleType = {
  SERVER_CLIENT_ROLE: 'SERVER_CLIENT_ROLE', // role, e.g. admin, manager, operator
  RESOURCE_CLIENT_ROLE: 'RESOURCE_CLIENT_ROLE', // resource group, holds resources like locations and enterprises
  USER_CLIENT_ROLE: 'USER_CLIENT_ROLE', // user group, holds users and resource/user groups
};
export type RoleTypeValue = (typeof RoleType)[keyof typeof RoleType];

export const LOCATION_ACCESS_GROUP_SUFFIX = 'Location Access Group';
export const ENTERPRISE_OPERATOR_SUFFIX = 'Enterprise Operator';
export const OPERATOR_GROUP_SUFFIX = 'Operator Group';
export const OperatingGroupType = {
  PLATFORM: 'platform',
  OPERATOR: 'operator',
  ENTERPRISE_OPERATOR: 'enterprise-operator',
  OPERATOR_GROUP: 'operator-group',
  OPERATING_GROUP: 'operating-group', // resource group and user group combo
  LOCATION_ACCESS: 'location-access', // location specific access
  TEAM: 'team',
  REGION: 'region',
  MARKET: 'market',
};
export type OperatingGroupTypeValue = (typeof OperatingGroupType)[keyof typeof OperatingGroupType];

export const RoleName = {
  SYSTEM_ADMIN: 'system admin',
  SUPER_ADMIN: 'super admin',
  OPERATIONAL_ADMIN: 'operational admin',
  LEADER: 'leader',
  MANAGER: 'manager',
  ENFORCEMENT: 'enforcement',
  VALET: 'valet',
  OPERATOR: 'operator',
  INTAKE: 'intake',
  PORTAL_ASSOCIATE: 'parking pass',
  PORTAL_ADMIN: 'portal admin',
};
export type RoleNameValue = (typeof RoleName)[keyof typeof RoleName];

export const KCClient = {
  TEST: 'account',
  LOCAL: 'metropolis-server-client',
  DEV: 'metropolis-server-client',
  STAGING: 'metropolis-server-client',
  PRODUCTION: 'metropolis-server-client',
};
export type KCClientValue = (typeof KCClient)[keyof typeof KCClient];

export const ResourceType = {
  UNKNOWN_RESOURCE_TYPE: 'UNKNOWN_RESOURCE_TYPE',
  SITE_RESOURCE: 'SITE_RESOURCE',
  SITE_GROUP_RESOURCE: 'SITE_GROUP_RESOURCE',
  ENTERPRISE_RESOURCE: 'ENTERPRISE_RESOURCE',
};
export type ResourceTypeValue = (typeof ResourceType)[keyof typeof ResourceType];

export interface IamResource {
  resourceId: string;
  name: string;
  resourceType: ResourceTypeValue;
}

export interface IamResourceRole {
  resourceRoleId: string;
  name: string;
  resources: IamResource[];
  roleId: string;
}

// Group or location specific access
export const LocationAccessType = {
  GROUP: 'group',
  LOCATION: 'location',
};
export type LocationAccessTypeValues = (typeof LocationAccessType)[keyof typeof LocationAccessType];

export interface OperatingGroupView {
  resources: IamResource[];
  role: IamRole;
  accessType: LocationAccessTypeValues;
}
